import React from "react";
import {Button, Card, CardContent, CardHeader, Container, Grid, Form, Message, Image} from "semantic-ui-react";
import {UsernameEmailField} from "../form-fields";
import {Link} from "gatsby";
import withTrans from "../../../i18n/withTrans";
import box from "../../../images/manage/divbox.png";
import t3 from "../../../images/teaching/t3.gif";
import btndl from "../../../images/teaching/btnysf.png";
import NavigationBar from "../navigation-bar";

function TeachingDetailView(props) {
    const {
        onContinue,
    } = props;
    const mode = detectOrient();
    return (
        <div className="whiteDiv" textAlign='center' style={{
            width:"100%",
            minHeight: "100vh",
            textAlign:"center",
            margin:"0 auto"
        }} >

<NavigationBar mode={mode} platform={"h5"} showLogo={1} showTitle={2} onBack={onContinue}/>

            <div style={{maxWidth: 450, height:"100vh", paddingTop:"80px", margin:"0 auto"}}>
                <div style={{padding:"3vh"}}>
                
                <div
        textAlign='center' style={{
            height: "100vh",
        }} verticalAlign='middle'
        >
            <div style={{maxWidth: 450, height:"100vh"}}>                
                
                <div fluid style={{
                    backgroundColor:"transparent",
                    backgroundImage: `url(${box})` , backgroundSize:'100% 100%',
                    borderRadius: '0.5rem',
                    boxShadow: '0 1px 1px 0 transparent, 0 0 0 1px transparent',
                    padding:"2em",
                }}>
                    
                    <Image style={{
                    marginTop: "2vh",
                    width:"100%"
                }} centered src={t3}/>

                <Image style={{
                    width:"100%",
                    marginBottom: "4vh",
                }} centered src={btndl} 
                onClick={() => {
                    window.location.href = 'https://app.95516.com/?r=download';
                }}/>

                </div>
                </div>

            
                
            
        </div>
                
                </div>
            </div>
        </div>
    
    );
}

export default withTrans(TeachingDetailView);

function detectOrient() {
    if (typeof window !== `undefined`) {
        // let isIOS = "0";
        // if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
        //     // 暂时去掉ios两板功能
        //     isIOS = "1";
        // }
        // if (isIOS === "0") return "PORTRAIT";
        if (window.innerHeight >= window.innerWidth) {
            // 竖屏
            // console.log("竖屏");
            return "PORTRAIT";
        } else {
            // 横屏
            // console.log("横屏");
            return "LANDSCAPE";
        }
    } else {
        return "PORTRAIT";
    }
}